import React,{useEffect, useState} from 'react';
import Header from "../../components/Header";
import Dire15Plan from "../../assets/images/dire-15.png";
import DireLogo from "../../assets/images/dire-logo.png";
//import BackgroundLights from "../../assets/video/background-lights.mp4";
import { E164Number } from "libphonenumber-js/types";
import "./style.scss";
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {  useFormik } from "formik";
import ApiClient from "../../services/apiClient";
import * as Yup from "yup";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

  export const Plan = () => {
    const apiClient = ApiClient.getInstance();
    const [marks,setMarks] = useState([]);
    const [models,setModels] = useState([]);
    const [modelsFiltered, setModelsFiltered] = useState([]);
    useEffect(() => {
        const catalogues = async () => { 
            const data = apiClient.getCatalogues().then((data) => {
                console.log(data.marks);
                setMarks(data.marks);
                setModels(data.models);
            })
        }
        catalogues();
    },[]);
    function handleChangeOption(e:any){
        console.log(e.target.value)
        setFieldValue("customerModelPhone", "");
        setFieldValue("customerOptionPhone", e.target.value);
        const data = models.filter((model: any) => model.mark === e.target.value);
        console.log(data);
        setModelsFiltered(data);
    }
    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      } = useFormik({
        enableReinitialize: true,
        initialValues: {
          nameCustomer: "",
          surnamesCustomer: "",
          emailCustomer: "",
          phoneCustomer: "",
          customerStreet: "",
          customerNoExterior: "",
          customerNoInterior: "",
          customerSuburb: "",
          customerPostalCode: "",
          customerDelegation: "",
          customerState: "",
          customerCountry: "",
          customerOptionPhone:"",
          customerModelPhone:"",
        },
        validationSchema: Yup.object().shape({
            nameCustomer: Yup.string().required('Requerido'),
            surnamesCustomer: Yup.string().required('Requerido'),
            emailCustomer: Yup.string().required('Requerido'),
            phoneCustomer: Yup.string().required('Requerido'),
            customerStreet: Yup.string().required('Requerido'),
            customerNoExterior: Yup.string().required('Requerido'),
            customerNoInterior: Yup.string().required('Requerido'),
            customerSuburb: Yup.string().required('Requerido'),
            customerPostalCode: Yup.string().required('Requerido'),
            customerDelegation: Yup.string().required('Requerido'),
            customerState: Yup.string().required('Requerido'),
            customerCountry: Yup.string().required('Requerido'),  
            customerOptionPhone: Yup.string().required('Requerido'),
            customerModelPhone: Yup.string().required('Requerido'),    
            
        }),
        onSubmit: (values) => {
            console.log(values);
        },
      });

    return (
        <>
        <Header />
        <div >
        <div className="" id="welcome"> 
        <img src={DireLogo} style={{ width: "40px", height: "50px" }} />
        </div>
        <div className="row">
            {/* <div className="col-md-1"></div> */}
            <div className=" m-5 col-md-7">
                <form id="div-perfil" onSubmit={handleSubmit}>
                    <div className="mb-2 col-md-12">
                        <Chip label="Información de contacto" color="secondary" variant="outlined" sx={{fontSize:20}}/>
                        <Divider
                        sx={{ bgcolor: (theme) => theme.palette.secondary.light }}
                        style={{
                            height: 2,
                            margin: 5,
                            width:'100%'
                        }}
                        />
                    </div>
                    <div className="mb-3 row">
                        <div className="col-md-6 ">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Nombre(s) <span className="requiredLabel">*</span></label>
                            <input 
                                type="email"  
                                id="nameCustomer" 
                                name="nameCustomer" 
                                onChange={handleChange} 
                                onBlur={handleBlur} 
                                value={values.nameCustomer}  
                                className={
                                    errors.nameCustomer ? " form-control is-invalid" : "form-control"
                                    }
                            />
                            {errors.nameCustomer && touched.nameCustomer &&(
                                                                    <div className="invalid-feedback">
                                                                    El campo Nombre es obligatorio.
                                                                    </div>
                                                                )}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Apellidos <span className="requiredLabel">*</span> </label>
                            <input 
                                type="email" 
                                id="surnamesCustomer" 
                                name="surnamesCustomer" 
                                onChange={handleChange} 
                                onBlur={handleBlur} 
                                value={values.surnamesCustomer}  
                                className={
                                    errors.surnamesCustomer ? " form-control is-invalid" : "form-control"
                                    }
                            />
                            {errors.surnamesCustomer && touched.surnamesCustomer &&(
                                                                    <div className="invalid-feedback">
                                                                    El campo Apellido es obligatorio.
                                                                    </div>
                                                                )}
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-md-6 ">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Correo electrónico <span className="requiredLabel">*</span></label>
                            <input 
                                type="email" 
                                id="emailCustomer" 
                                name="emailCustomer"
                                onChange={handleChange} 
                                onBlur={handleBlur} 
                                value={values.emailCustomer}  
                                className={
                                    errors.emailCustomer ? " form-control is-invalid" : "form-control"
                                    } 
                            />
                            {errors.emailCustomer && touched.emailCustomer &&(
                                                                    <div className="invalid-feedback">
                                                                    El campo Correo electrónico es obligatorio.
                                                                    </div>
                                                                )}                        
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Teléfono (10 dígitos) <span className="requiredLabel">*</span> <><Tooltip title="asd"><IconButton><InfoOutlinedIcon /></IconButton></Tooltip></></label>
                            <input 
                                type="email" 
                                id="phoneCustomer"
                                name="phoneCustomer"
                                onChange={handleChange} 
                                onBlur={handleBlur} 
                                value={values.phoneCustomer}  
                                className={
                                    errors.phoneCustomer ? " form-control is-invalid" : "form-control"
                                    } 
                            />
                            {errors.phoneCustomer && touched.phoneCustomer &&(
                                                                    <div className="invalid-feedback">
                                                                    El campo Teléfono es obligatorio.
                                                                    </div>
                                                                )}                        
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <h5 style={{color:'white'}}>Dispositivo a usar con tu plan</h5>
                        <label className="form-label mt-1">Esto para garantizar un buen servicio</label>
                        <div className="col-md-6 ">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Marca de tu equipo <span className="requiredLabel">*</span></label>
                            <select 
                                id="customerOptionPhone" 
                                name="customerOptionPhone"
                                onChange={handleChangeOption} 
                                onBlur={handleChangeOption} 
                                value={values.customerOptionPhone}  
                                className={
                                    errors.customerOptionPhone ? " form-control is-invalid" : "form-control"
                                    } 
                            >
                                <option selected>Selecciona un dispositivo</option>
                                {marks.map((mark: any, index) => (
                                    <option key={mark.id} value={mark.name}>{mark.name}</option>
                                ))
                                }
                            </select>
                            {errors.customerOptionPhone && touched.customerOptionPhone &&(
                                                                    <div className="invalid-feedback">
                                                                        Elige la marca de tu equipo
                                                                    </div>
                                                                )}                        
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Modelo de tu equipo <span className="requiredLabel">*</span> </label>
                            <select 
                                id="customerModelPhone"
                                name="customerModelPhone"
                                onChange={handleChange} 
                                onBlur={handleBlur} 
                                value={values.customerModelPhone}  
                                className={
                                    errors.customerModelPhone ? " form-control is-invalid" : "form-control"
                                    } 
                            >
                                <option selected>Selecciona un dispositivo</option>
                                {modelsFiltered.map((model: any, index) => (
                                    <option key={model.id} value={model.model_name}>{model.model_name}</option>
                                ))
                                }
                            </select>
                            {errors.customerModelPhone && touched.customerModelPhone &&(
                                                                    <div className="invalid-feedback">
                                                                        Elige el modelo de tu equipo
                                                                    </div>
                                                                )}                        
                        </div>
                    </div>
                    <div className="mb-5 row">
                    <div className="form-check m-2">
                        <div className="col-md-8 ">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate"/>
                        <label className="form-check-label " htmlFor="flexCheckIndeterminate" style={{color: "white"}}>
                            Si adquiriste tu dispositivo en el extranjero, marca esta opción. 
                        </label>
                    </div>
                        </div>
                       
                    </div>
                    <div className="mb-2 col-md-12">
                        <Chip label="Dirección de envío" color="secondary" variant="outlined" sx={{fontSize:20}}/>
                        <Divider
                        sx={{ bgcolor: (theme) => theme.palette.secondary.light }}
                        style={{
                            height: 2,
                            margin: 5,
                            width:'100%'
                        }}
                        />
                    </div>
                    <div className="mb-3 row">
                        <div className="col-md-6 ">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Calle <span className="requiredLabel">*</span></label>
                            <input 
                                type="email"
                                id="customerStreet" 
                                name="customerStreet" 
                                onChange={handleChange} 
                                onBlur={handleBlur} 
                                value={values.customerStreet}  
                                className={
                                    errors.phoneCustomer ? " form-control is-invalid" : "form-control"
                                    } 
                            />
                            {errors.customerStreet && touched.customerStreet &&(
                                                                    <div className="invalid-feedback">
                                                                    El campo Calle es obligatorio.
                                                                    </div>
                                                                )} 
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Número Exterior <span className="requiredLabel">*</span> </label>
                            <input 
                                type="email"  
                                id="customerNoExterior" 
                                name="customerNoExterior" 
                                onChange={handleChange} 
                                onBlur={handleBlur} 
                                value={values.customerNoExterior}  
                                className={
                                    errors.customerNoExterior ? " form-control is-invalid" : "form-control"
                                    } 
                            />
                            {errors.customerNoExterior && touched.customerNoExterior &&(
                                                                    <div className="invalid-feedback">
                                                                    El campo Número exterior es obligatorio.
                                                                    </div>
                                                                )} 
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-md-6 ">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Número Interior <span className="requiredLabel">*</span></label>
                            <input 
                                type="email" 
                                id="customerNoInterior" 
                                name="customerNoInterior" 
                                onChange={handleChange} 
                                onBlur={handleBlur} 
                                value={values.customerNoInterior}  
                                className={
                                    errors.customerNoInterior ? " form-control is-invalid" : "form-control"
                                    } 
                            />
                            {errors.customerNoInterior && touched.customerNoInterior &&(
                                                                    <div className="invalid-feedback">
                                                                    El campo Número interior es obligatorio.
                                                                    </div>
                                                                )} 
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Colonia <span className="requiredLabel">*</span> </label>
                            <input 
                                type="email" 
                                id="customerSuburb" 
                                name="customerSuburb" 
                                onChange={handleChange} 
                                onBlur={handleBlur} 
                                value={values.customerSuburb}  
                                className={
                                    errors.customerSuburb ? " form-control is-invalid" : "form-control"
                                    } 
                            />
                            {errors.customerSuburb && touched.customerSuburb &&(
                                                                    <div className="invalid-feedback">
                                                                    El campo Colonia interior es obligatorio.
                                                                    </div>
                                                                )} 
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-md-6 ">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Código Postal <span className="requiredLabel">*</span></label>
                            <input 
                                type="email"  
                                id="customerPostalCode" 
                                name="customerPostalCode"  
                                onChange={handleChange} 
                                onBlur={handleBlur} 
                                value={values.customerPostalCode}  
                                className={
                                    errors.customerPostalCode ? " form-control is-invalid" : "form-control"
                                    } 
                            />
                            {errors.customerPostalCode && touched.customerPostalCode &&(
                                                                    <div className="invalid-feedback">
                                                                    El campo Código Postal interior es obligatorio.
                                                                    </div>
                                                                )} 
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Municipio / Alcaldía <span className="requiredLabel">*</span> </label>
                            <input 
                                type="email"  
                                id="customerDelegation" 
                                name="customerDelegation"  
                                onChange={handleChange} 
                                onBlur={handleBlur} 
                                value={values.customerDelegation}  
                                className={
                                    errors.customerDelegation ? " form-control is-invalid" : "form-control"
                                    } 
                            />
                            {errors.customerDelegation && touched.customerDelegation &&(
                                                                    <div className="invalid-feedback">
                                                                    El campo Municipio interior es obligatorio.
                                                                    </div>
                                                                )} 
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-md-6 ">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Estado <span className="requiredLabel">*</span></label>
                            <input 
                                type="email" 
                                id="customerState" 
                                name="customerState"   
                                onChange={handleChange} 
                                onBlur={handleBlur} 
                                value={values.customerState}  
                                className={
                                    errors.customerState ? " form-control is-invalid" : "form-control"
                                    } 
                            />
                            {errors.customerState && touched.customerState &&(
                                                                    <div className="invalid-feedback">
                                                                    El campo Estado es obligatorio.
                                                                    </div>
                                                                )} 
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="exampleFormControlInput1" className="form-label">País <span className="requiredLabel">*</span> </label>
                            <input 
                                type="email" 
                                id="customerCountry" 
                                name="customerCountry"   
                                onChange={handleChange} 
                                onBlur={handleBlur} 
                                value={values.customerCountry}  
                                className={
                                    errors.customerCountry ? " form-control is-invalid" : "form-control"
                                    } 
                            />
                            {errors.customerCountry && touched.customerCountry &&(
                                                                    <div className="invalid-feedback">
                                                                    El campo País es obligatorio.
                                                                    </div>
                                                                )} 
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Continuar
                        
                        </button>
                </form>
            </div>
            <div className="m-5 col-md-3 md-1" >
                <div className="mb-2 col-md-12">
                    <Chip label="Pedido" color="secondary" variant="outlined" sx={{fontSize:20}}/>
                    <Divider
                    sx={{ bgcolor: (theme) => theme.palette.secondary.light }}
                    style={{
                        height: 2,
                        margin: 5,
                        width:'100%'
                    }}
                    />
                </div>
                <div className="mb-3 row">
                    <div className="col-md-6 col sm-6">
                    <Stack direction="row" spacing={2}>
                    <Button variant="contained" color="info">
                        -
                    </Button>
                    <TextField
          required
          id="outlined-required"
          defaultValue="1"
          sx={{color:'white'}}
        />
                    <Button variant="contained" color="info">
                        +
                    </Button>
                    </Stack>
                    </div>
                </div>
            </div>

        </div>




        </div>
</>
      
    //   <div id="welcome" style={{
    //     backgroundColor:'#24135f', width: "100%", height: "100%" 
    //   }}>

    //     <img src={DireLogo} style={{ width: "40px", height: "50px" }} />
    //     <h3>Informacion de contacto</h3>
    //     <TextField
    //       sx={{ input: { color: 'red' } }}
    //       required
    //       id="outlined-required"
    //       label="Required"
    //       defaultValue="Hello World"
    //     />

    //   </div>
  )}
