import React,{useEffect, useState, useRef} from 'react';
import Header from "../../components/Header";
import Footer from '../../components/Footer';
import DireLogo from "../../assets/images/dire-logo.png";
import "./style.scss";
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import {  useFormik } from "formik";
import ApiClient from "../../services/apiClient";
import * as Yup from "yup";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DataCustomer  from './info-personal';
import { useParams } from 'react-router';
import  AddressCustomer  from './address-personal';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Badge from '@mui/material/Badge';
import ButtonGroup from '@mui/material/ButtonGroup';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import moment from 'moment';
import { CSSTransition } from "react-transition-group";
import PaymentIframe from "../../components/PaymentIframe";
import CircularProgress from '@mui/material/CircularProgress';

  export const Test = () => {
    
    const apiClient = ApiClient.getInstance();
    const params = useParams();
    const [marks,setMarks] = useState([]);
    const [models,setModels] = useState([]);
    const [modelsFiltered, setModelsFiltered] = useState([]);
    const [noChips,setNoChips] = useState(1);
    const [costPlans,setCostPlans] = useState(0);
    const [costSim,setCostSim] = useState(0);
    const [amountChip,setAmountChip] = useState(costSim);
    const [amountPlan,setAmountPlan] = useState(0);
    const [amountTotal,setAmountTotal] = useState(costPlans + costSim);
    const [amountEnvio,setAmountEnvio] = useState(0);
    const [colonias, setColonias] = useState([] as any);
    const containerRef = React.useRef(null);
    const [showDiv,setShowDiv] = useState(false);
    const [isIncoompleted,setIsIncompleted] = useState(true)//estado para el div de pedido
    const [currentPage, setCurrentPage] = useState(0);
    const nextStep = () => setCurrentPage((prev) => prev + 1);
    const prevStep = () => setCurrentPage((prev) => prev - 1);
    const [isValidF1, setIsValidF1] = useState(false);
    const [isValidF2, setIsValidF2] = useState(false);
    const ref1 = useRef<null | HTMLElement>(null);
    const ref2 = useRef<null | HTMLDivElement>(null);
    const [plan, setPlan] = useState(null);    
    const [openMessage, setOpenMessage] = useState(true);
    const [message, setMessage] = useState("");
    const [frequency, setFrequency] = useState("");
    const nodeRef = useRef(null);
    const nodeRef2 = useRef(null);
    const nodeRef3 = useRef(null);
    const nodeRef4 = useRef(null);
    const nodeRef5 = useRef(null);
    const [change, setChange] = useState(false);
    const [openPaymentIframe, setOpenPaymentIframe] = useState(false);
    const [urlPayment, setUrlPayment] = useState("");
    const [reference, setReference] = useState("");
    const [planName, setPlanName] = useState("");
    const [planNameC, setPlanNameC] = useState("");
    const [day1, setDay1] = useState(null);
    const [day2, setDay2] = useState(null);
    const [isPaying, setIsPaying] = useState(false);
    const plans: { [key: string]: any } = {
        "DIRE-ILIMITADO": { name: "Dire 20", id: 1 },
        "DIRE-8": { name: "Dire 8", id: 4 },
        "DIRE-5": { name: "Dire 5", id: 5 },
        "DIRE-ILIMITADO-3": { name: "Dire Ilimitado Trimestral", id: 9 },
        "DIRE-ILIMITADO-6": { name: "Dire Ilimitado Semestral", id: 10 },
        "DIRE-ILIMITADO-12": { name: "Dire Ilimitado Anual", id: 12 },
        "DIRE-TEST": { name: "Dire test", id: 1000 },
        "DIRE-100":{ name: "Dire 100", id: 13},
        "DIRE-ILIMITADO-PLUS":{ name: "Dire ilimitado plus", id:14}
      };
    const getCurrentTimestamp = () => {
        return Math.floor(Date.now() / 1000);
      };
    
      async function  setReferenceNew   ()  {
        const timestamp = getCurrentTimestamp();
        let a = '';
        const response = await apiClient.getAleatorio().then((data: any) =>{
            console.log(planName)
            a = `SIM_${planName}_${timestamp}_${data.trim()}`;
            return a;
            //setReference(`${planName}_${timestamp}_${data}-${timestamp}`);
        })
        //const reference = references ;//`${planName}_${timestamp}_${aleatorio}`;
        console.log(response)
        setReference(response);
       return response;
      };
    const [formData,setFormData] = useState({
        step1:{
            nameCustomer: "",
            surnamesCustomer: "",
            emailCustomer: "",
            phoneCustomer: "",
            customerOptionPhone:"",
            customerModelPhone:"",
            isForeign: false,
            IMEI:"",
            isPortability: false,
            phonePortability: "",
            CURPPortability: "",
            NIPPortability: "",

        },
        step2:{
            customerStreet: "",
            customerNoExterior: "",
            customerNoInterior: "",
            customerSuburb: "",
            customerPostalCode: "",
            customerDelegation: "",
            customerState: "",
            customerCountry: "",
            estimatedDeliveryTime: "",
        }
    });
    useEffect(() => {
        const catalogues = async () => { 
            console.log(params);
            const data = apiClient.getCatalogues().then((data:any) => {
                setMarks(data.marks);
                setModels(data.models);
                let planes = data.plans;
                console.log(data.plans);
                let pln = planes.filter((plane: any) => plane.id === params.id);
                console.log(pln[0])
                setPlanName(pln[0].name_web)
                setPlanNameC(pln[0].nombre)
                setCostPlans(pln[0].valor_comercial);
                setAmountPlan(pln[0].valor_comercial);
                setCostSim(pln[0].cost_sim);
                setAmountChip(pln[0].cost_sim)
                setPlan(pln[0].id);
                setAmountTotal(parseFloat(pln[0].valor_comercial) + parseFloat(pln[0].cost_sim));
                let p : any = {
                    'Mensual':'mes',
                    '90 días': 'trimestral',
                    '180 días': 'semestral',
                    '360 días': 'anual',
                }
                setFrequency(p[pln[0].forma_pago])
                setShowDiv(true);
            })
            
        }
        catalogues();
        setCurrentPage(0);
    },[]);
    function handleChangeOption(e:any){
        setFieldValue("customerModelPhone", "");
        setFieldValue("customerOptionPhone", e.target.value);
        const data = models.filter((model: any) => model.mark === e.target.value); 
        setModelsFiltered(data);
    }
    function next(){
        setChange(!change);
        let limit = 5;
        if(noChips < 5) {
            setNoChips(noChips + 1);
            updateQuantity(noChips + 1);
        } else {
            updateQuantity(noChips);
        }
        
    }
    function updateQuantity(chips:number){
        let amountPlan = costPlans * chips;
        let amountChip = costSim * chips;
        setAmountPlan(amountPlan);
        setAmountChip(amountChip);
        setAmountTotal(amountPlan + amountChip);
    }

    function previous(){
        setChange(!change);
        let limit = 1;
        if(noChips > 1) {
            setNoChips(noChips - 1);
            updateQuantity(noChips - 1);
        } else {
            updateQuantity(noChips);
        }
    }

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      } = useFormik({
        enableReinitialize: true,
        initialValues: {
          customerNumChips: noChips,
          customerPlan: plan,
          amountTotal: amountTotal,
          amountChip: amountChip,
          amountPlan: amountPlan,
        },
        validationSchema: Yup.object().shape({ 
            customerNumChips: Yup.string().required('Requerido'),
            customerPlan: Yup.string().required('Requerido'),  
            
        }),
        onSubmit: async (values) => {
            setIsPaying(true)
            const  data = {
                customer:{
                    name: formData.step1.nameCustomer,
                    surnames: formData.step1.surnamesCustomer,
                    email: formData.step1.emailCustomer,
                    phone: formData.step1.phoneCustomer,
                    phone_mark_id: formData.step1.customerOptionPhone,
                    model_phone_mark: formData.step1.customerModelPhone,
                    is_foreign: formData.step1.isForeign,
                    imei: formData.step1.IMEI,
                    is_portability: formData.step1.isPortability,
                    phone_portability: formData.step1.phonePortability,
                    CURP_portability: formData.step1.CURPPortability,
                    NIP_portability: formData.step1.NIPPortability,
                },
                address:{
                    street: formData.step2.customerStreet,
                    noExterior: formData.step2.customerNoExterior,
                    noInterior: formData.step2.customerNoInterior,
                    suburb: formData.step2.customerSuburb,
                    postalCode: formData.step2.customerPostalCode,
                    delegation: formData.step2.customerDelegation,
                    state: formData.step2.customerState,
                    country: formData.step2.customerCountry,
                    estimated_delivery_time: formData.step2.estimatedDeliveryTime,
                    location_id: ""
                },
                customerNumChips: values.customerNumChips,
                plan: values.customerPlan,
                amount: values.amountTotal,
                amount_chip: values.amountChip,
                amount_plan: values.amountPlan,
            }
            localStorage.setItem('transaction',JSON.stringify(data) );
            let stock = {
                customerNumChips: values.customerNumChips,
                plan: values.customerPlan,
            }
            // console.log(data,stock)
            // apiClient.createCustomer(data).then((response) => {
            //     console.log(response);
            // })
            // apiClient.validateStock(stock).then((response) => {
            //     if(response.error) {
            //         setMessage(response.error);
            //         setSeverity("error");
            //         setOpenMessage(true);
            //     } else {
                    // setReferenceNew().then(() => {
                    //     console.log(reference, planName,values.amountTotal, formData.step1.phoneCustomer);
                        createPaymentLink(data).then(() => {
                            console.log(reference)
                            setOpenPaymentIframe(true)
                        })
                    //});
                    
                    // apiClient.createCustomer(data).then((response) => {
                    //     console.log(response);
                    // })
                //}
            //})
           
        },
      });
      const createPaymentLink = async(data:any) => {
        let urlPayment : any = ""
        let referenceG : any = ""
        await setReferenceNew().then(async(a)=>{
            setReference(a);
            referenceG = a
            data.customer.reference = a;
            await apiClient.loginInto99Minutes().then((response:any) =>{
                localStorage.setItem('AUTH_TOKEN_SESSION_99_MINUTES', response.access_token);
                apiClient.token_99_minutes = response.access_token;
                let address = {
                    address: `Calle ${data.address.street} ${data.address.noExterior} ${data.address.noInterior !== '' ? data.address.noInterior : ''}, ${data.address.suburb}, ${data.address.postalCode} ${data.address.delegation}, ${data.address.state}, ${data.address.country}`
                }
                 apiClient.registerLocation(address).then((r:any) => {
                    if(r.success){
                        data.address.location_id = r.success.data[0].locationId;
                    }
                     apiClient.createCustomer(data).then(async(r) => {
                        if(r.success) {
                            await apiClient.getURLPayment(
                                a,
                                values.amountTotal,
                                formData.step1.phoneCustomer,
                                planName,
                                noChips
                              ).then((a) => {
                                urlPayment = a;
                              });
                          
                              if (urlPayment.status != "success") {
                                return;
                              }
                              //alert(urlPayment);
                              setUrlPayment(urlPayment.url);
                              const transaction = {
                                plan_id: plans[planName].id,
                                plan_name: planName,
                                reference: referenceG,
                                phone_number: formData.step1.phoneCustomer,
                                status: "pending",
                                datetime: new Date(),
                                url_payment: urlPayment.url,
                              };
                          
                              apiClient.saveTransaction(transaction).then(() => {
                                
                              });
                        }
                    })
                })
            })

            
        });
       
      
      }
    useEffect(() => {
        console.log('[isValidF1,isValidF2]',[isValidF1,isValidF2])
        if(isValidF1){
            ref1.current?.scrollIntoView({behavior: 'smooth'});
        }
        if(isValidF1 && isValidF2){
            setIsIncompleted(false);
            ref2.current?.scrollIntoView({behavior: 'smooth'});
        } else {
            setIsIncompleted(true);
        }
      },[isValidF1,isValidF2])
      const handleClose = () => {
        setOpenMessage(false);
      };
      const pages : any = [
        <DataCustomer nextStep={nextStep} setFormData={setFormData} formData={formData} setIsValidF1={setIsValidF1} valuesIndex={values}/>,
        <AddressCustomer prevStep={prevStep} setFormData={setFormData} formData={formData}  setIsValidF2={setIsValidF2} ref1={ref1} valuesIndex={values} setDay1 ={setDay1} setDay2={setDay2} />
      ];
      useEffect(() => {
        apiClient.getAleatorio().then((response) => {
            console.log('aleatorio',response);
        })
      },[])
    return (
        <>
         {openPaymentIframe && (
        <PaymentIframe url={urlPayment} handleClose={setOpenPaymentIframe} />
      )}
        <Header />

        <div className="" id="welcome"> 
        <img src={DireLogo} style={{ width: "40px", height: "50px" }} />
        </div>
        <Box sx={{  maxWidth:'1700px', margin:"0 auto" }}>
            {/* <form id="div-perfil" onSubmit={handleSubmit}> */}
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm:2, md: 3, xl:10 }} p={5} m={1}>
                    <Grid item xs={0} md={1} xl={1}></Grid>
                    <Grid item xs={11} md={7} xl={7}>
                        {pages[0]}
                        {isValidF1 ? pages[1] : ''}
                    </Grid>
                    
                    {/* </Slide> */}
                    <Grid item xs={11} md={3} xl={3} sx={{opacity: 
                        isIncoompleted ? 0.6 : 1}} ref={ref2}>
                        <form onSubmit={handleSubmit}>
                            <Grid item md={12}>
                            <Chip label="Pedido" color="secondary" variant="outlined" sx={{fontSize:20,color:'white'}}/>
                                <Divider
                                sx={{ bgcolor: (theme) => theme.palette.secondary.light }}
                                style={{
                                    height: 2,
                                    margin: 5,
                                    width:'100%'
                                }}
                                />
                            </Grid>
                            <div className="text-center">
                                <label htmlFor="" style={{color:'white'}} className="text-end"  >
                                                    <strong> 
                                                        {planNameC}
                                                    </strong>
                                                </label>
                                
                                
                            </div>
                            <Grid item md={12} mt={1}>
                                {/* <Stack direction="row" spacing={{ xs: 0.1, sm: 2, md: 2 }} justifyContent="flex-end">
                                        <Button variant="contained" color="info" onClick={previous} >
                                        -
                                        </Button>
                                        <div className="formInputQuantity">
                                                <input 
                                                    id="customerNumChips"  
                                                    name="customerNumChips"
                                                    className="inputQuantity" 
                                                    maxLength={1}
                                                    min={1}
                                                    max={5}
                                                    type="number"
                                                    onChange={handleChange}
                                                    onBlur={handleChange}
                                                    value={values.customerNumChips} 
                                                    disabled={true}
                                                    />
                                        </div>
                                        <Button variant="contained" color="info" onClick={next} >
                                            +
                                        </Button>
                                    <label htmlFor="" style={{color:'white'}} className="text-right"><strong>$ {amountPlan} / {frequency}</strong></label>

                            
                                </Stack> */}
                                <Box
                                        sx={{
                                            color: 'action.active',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            '& > *': {
                                            marginBottom: 2,
                                            },
                                            '& .MuiBadge-root': {
                                            marginRight: 4,
                                            },
                                        }}
                                        >
                                            <Stack direction="row" spacing={{ xs: 1, sm: 1, md: 2, xl:1 }} justifyContent="flex-start">
                                        <Grid item md={8}>
                                            <CSSTransition
                                                    nodeRef={nodeRef5}
                                                    in={change}
                                                    timeout={200}
                                                    classNames="my-node"
                                                >
                                                <Badge color="primary" badgeContent={noChips} ref={nodeRef5}>
                                                <AddShoppingCartIcon sx={{color:'white'}} />
                                                </Badge>
                                            </CSSTransition>
                                            <ButtonGroup >
                                            <Button
                                                aria-label="reduce"
                                                onClick={previous}
                                            >
                                                <RemoveIcon fontSize="small" />
                                            </Button>
                                            <Button
                                                aria-label="increase"
                                                onClick={next}
                                            >
                                                <AddIcon fontSize="small" />
                                            </Button>
                                            {/* <FormLabel sx={{marginLeft:2}} component="legend">{amountPlan} / {frequency}</FormLabel> */}
                                            </ButtonGroup>
                                            
                                        </Grid>
                                        <Grid item md={3}>
                                        <CSSTransition
                                            nodeRef={nodeRef}
                                            in={change}
                                            timeout={200}
                                            classNames="my-node"
                                        >
                                                <label htmlFor="" style={{color:'white'}} className="text-right" ref={nodeRef}>
                                                    <strong>$ 
                                                        {new Intl.NumberFormat("en-US", {
                                                            minimumFractionDigits: 2,
                                                        }).format(amountPlan)} / {frequency}
                                                    </strong>
                                                </label>
                                                {/* <div ref={nodeRef}>
                                                    <h2 className="">
                                                        <strong>$ 
                                                            {new Intl.NumberFormat("en-US", {
                                                                minimumFractionDigits: 2,
                                                            }).format(amountPlan)} / {frequency}
                                                        </strong>
                                                    </h2>
                                                </div> */}
                                        </CSSTransition>
                                        </Grid>
                                        
                                        </Stack>
                                        
                                        </Box>
                            </Grid>
                            <Grid item md={12} mt={1}>
                                    <Divider
                                    sx={{ bgcolor: (theme) => theme.palette.secondary.light }}
                                    style={{
                                        height: 2,
                                        margin: 5,
                                        width:'100%'
                                    }}
                                    />
                            </Grid>
                            <Grid container  mt={1}>
                                    <CSSTransition
                                        nodeRef={nodeRef4}
                                        in={change}
                                        timeout={200}
                                        classNames="my-node"
                                    >
                                        <Grid item md={10} xs={8} ref={nodeRef4}>
                                            <label htmlFor="" className="text-left" style={{color:'white'}}>{noChips} Tarjeta Sim</label>
                                        </Grid>
                                    </CSSTransition>
                                    <Grid item md={2} xs={4}>
                                        <CSSTransition
                                                nodeRef={nodeRef2}
                                                in={change}
                                                timeout={200}
                                                classNames="my-node"
                                            >
                                            <label htmlFor="" style={{color:'white'}} className='text-rigth' ref={nodeRef2}>
                                                <strong>{amountChip == 0.00 ? 'Gratis' : `$ ${new Intl.NumberFormat("en-US", {
                                                minimumFractionDigits: 2,
                                                }).format(amountChip)}`} 
                                                </strong>
                                            </label>
                                        </CSSTransition>
                                    </Grid>
                                    <Grid item md={10} xs={8}>
                                        <label htmlFor="" className="text-left" style={{color:'white'}}>Envío</label>
                                    </Grid>
                                    <Grid item md={2} xs={4}>
                                    <label htmlFor="" style={{color:'white'}} ><strong>{amountEnvio === 0 ? 'Gratis' : `$ ${new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                            }).format(amountEnvio)}`} </strong></label>
                                    </Grid>
                            </Grid>
                            <Grid item md={12} mt={2}>
                                    <Divider
                                    sx={{ bgcolor: (theme) => theme.palette.secondary.light }}
                                    style={{
                                        height: 2,
                                        margin: 5,
                                        width:'100%'
                                    }}
                                    />
                            </Grid>
                            <Grid container  mt={1}>
                                    <Grid item md={8} xs={6}>
                                        <label htmlFor="" className="text-left" style={{color:'white'}}><strong>Total del pedido</strong></label>
                                    </Grid>
                                    <Grid item md={4} xs={5}>
                                    <CSSTransition
                                                nodeRef={nodeRef3}
                                                in={change}
                                                timeout={200}
                                                classNames="my-node"
                                            >
                                        <label htmlFor="" style={{color:'white'}} className='text-rigth' ref={nodeRef3}>
                                                <strong>{`$${new Intl.NumberFormat("en-US", {
                                                        minimumFractionDigits: 2,
                                                        }).format(amountTotal)}`}</strong>
                                        </label>
                                    </CSSTransition>
                                    </Grid>
                                    <Grid item md={12} xs={12}  mt={2} display="inline">  
                                    { isValidF2 ?  <>
                                    <LocalShippingIcon fontSize="medium"  sx={{color:'#32ae4f'}}/>                                    
                                        <label htmlFor="" className="text-left p-1" style={{color:'#32ae4f'}}><strong>   {day1  && day2  ?  `Llega entre ${moment().add(day1, 'days').format('dddd')} ${moment().add(day1, 'days').format('DD')} y ${moment().add(day2, 'days').format('dddd')} ${moment().add(day2, 'days').format('LL')} comprando ahora` : `Llega el ${moment().add(day1, 'days').format('dddd')} ${moment().add(day1, 'days').format('LL')} comprando ahora`}</strong></label></>  : ''
                                    }
                                    </Grid>
                            </Grid>
                            <Grid>
                                <Stack direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                spacing={12}
                                mt={4}>
                                   { isIncoompleted ? '' : <Button variant="contained" type='submit' size="large" color='warning' fullWidth 
                                    >{!isPaying ? 'Pagar' : <CircularProgress color="success" />}</Button>} 
                                </Stack>
                            </Grid>  
                            <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'right'}} 
                            open={openMessage}
                            onClose={handleClose}
                            autoHideDuration={3000}
                        >
                            <Alert severity={"error"} variant="filled" onClose={handleClose} elevation={10} >
                                    {message}
                          </Alert>
                        </Snackbar>  
                                          
                        </form>
                        
                    </Grid>
                    <Grid item xs={0} md={1} xl={1}></Grid>
                </Grid>            
            {/* </form> */}
        </Box>
        <Footer />
        </>
  )}
