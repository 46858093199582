import Logo from "../../assets/images/dire-logo-loop.gif";
import MenuIcon from "../../assets/images/menu.png";
import MenuVideo from "../../assets/video/menu.mp4";
import 'moment/locale/es';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTiktok,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";

import "./style.scss";
import { useState } from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

function Header() {
  const [displayMenu, setDisplayMenu] = useState(false);

  const handleClick = () => {
    if (displayMenu) {
      setDisplayMenu(false);
    } else {
      setDisplayMenu(true);
    }
  };

  return (
    <div className="header">
      <div className="top-header">
        <div className="logo">
          <a href="https://diremovil.com/">
            <img src={Logo} />
          </a>
        </div>
        <div className="menu-icon">
          {displayMenu ? (
            <FontAwesomeIcon icon={faXmark} onClick={handleClick} />
          ) : (
            <img src={MenuIcon} onClick={handleClick} />
          )}
        </div>
      </div>
      <div className="menu" style={{ display: displayMenu ? "" : "none" }}>
        <div className="social-media-icons">
          <div className="icons">
            <a
              href="https://www.instagram.com/diremovil/?igshid=YmMyMTA2M2Y="
              target="_blank"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="#" target="_blank">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="https://www.tiktok.com/@diremovil" target="_blank">
              <FontAwesomeIcon icon={faTiktok} />
            </a>
          </div>
          <h2>¿Quieres hacer una recarga?</h2>
          <p>
            <b>Comunicate</b> con nosotros
          </p>
          <b>333 648 9609</b>
        </div>
        <div className="links">
          <a href="https://diremovil.com/">HOME</a>
          <a href="http://diremovil.com/somos.html">¿POR QUÉ DIRÉ?</a>
          <a href="http://diremovil.com/planes.html">
            <h1>PLANES</h1>
          </a>
          <a href="http://diremovil.com/faqs.html">FAQ'S</a>
          <a href="http://diremovil.com/terminos.html">TERMINOS</a>
          <a href="http://diremovil.com/consideraciones.html">
            CONSIDERACIONES
          </a>
          <a href="http://diremovil.com/aviso.html">AVISO DE PRIVACIDAD</a>
        </div>
        <div className="video">
          <video muted loop autoPlay className="menu-video">
            <source src={MenuVideo} type="video/mp4"></source>
          </video>
        </div>
      </div>
    </div>
  );
}

export default Header;
