import React,{useEffect, useState} from 'react';
import "./style.scss";
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import {  useFormik } from "formik";
import ApiClient from "../../services/apiClient";
import * as Yup from "yup";
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import moment from 'moment';
 function AddressCustomer  ({prevStep, formData, setFormData, setIsValidF2, ref1, valuesIndex, setDay1, setDay2}:{prevStep:any, formData:any, setFormData:any, setIsValidF2:any, ref1:any, valuesIndex: any, setDay1: any, setDay2: any})  {
    const apiClient = ApiClient.getInstance();
    const [colonias, setColonias] = useState([]);
    const containerRef = React.useRef(null);
    const [showDiv,setShowDiv] = useState(false);
      
    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isValid
      } = useFormik({
        enableReinitialize: true,
        isInitialValid:false,
        validateOnMount:true,
        initialValues: {
          customerStreet: formData.step2.customerStreet,
          customerNoExterior: formData.step2.customerNoExterior,
          customerNoInterior: formData.step2.customerNoInterior,
          customerSuburb: formData.step2.customerSuburb,
          customerPostalCode: formData.step2.customerPostalCode,
          customerDelegation: formData.step2.customerDelegation,
          customerState: formData.step2.customerState,
          customerCountry: formData.step2.customerCountry,
          estimatedDeliveryTime: formData.step2.estimatedDeliveryTime
        },
        validationSchema: Yup.object().shape({
            customerStreet: Yup.string().required('Requerido'),
            customerNoExterior: Yup.string().required('Requerido'),
            //customerNoInterior: Yup.string().required('Requerido'),
            customerSuburb: Yup.string().required('Requerido'),
            customerPostalCode: Yup.string().required('Requerido'),
            customerDelegation: Yup.string().required('Requerido'),
            customerState: Yup.string().required('Requerido'),
            customerCountry: Yup.string().required('Requerido'), 
            
        }),
        onSubmit: (values) => {
            setIsValidF2(true);
            let step2 = {
                customerStreet: values.customerStreet,
                customerNoExterior: values.customerNoExterior,
                customerNoInterior: values.customerNoInterior,
                customerSuburb: values.customerSuburb,
                customerPostalCode: values.customerPostalCode,
                customerDelegation: values.customerDelegation,
                customerState: values.customerState,
                customerCountry: values.customerCountry,
                estimatedDeliveryTime: values.estimatedDeliveryTime
            }
            formData.step2 = step2;
            setFormData(formData);
        },
      });

      useEffect(() => {
  
        const setPostalCode = async () => {
    
          if(values.customerPostalCode.trim().length == 5){
            const postalCode = values.customerPostalCode.trim();
            setDay1(null);
            setDay2(null);
            apiClient
              .getAdrressFromZipCode(postalCode)
              .then((data:any) => {
                console.log(data?.colonias);
                setFieldValue('customerState', data.estado);
                setFieldValue('customerDelegation', data.municipio);
                setFieldValue('customerCountry', "México");
                setFieldValue('postalCode', values.customerPostalCode);
                setColonias(data.colonias);
                let a = {
                    zip_code:postalCode
                };
                apiClient.validateDeliveryTime(a).then((data) => {
                    if(data.coverage){
                        setDay1(data.coverage.day1);
                        setDay2(data.coverage.day2);
                        let a =  `${moment().add(data.coverage.day1, 'days').format('dddd')} ${moment().add(data.coverage.day1, 'days').format('DD')} y ${moment().add(data.coverage.day2, 'days').format('dddd')} ${moment().add(data.coverage.day2, 'days').format('LL')}` ; 
                        setFieldValue("estimatedDeliveryTime",a)
                    } else if(data.no_coverage){
                        let a = `
                        ${moment().add(data.no_coverage.days, 'days').format('dddd')} ${moment().add(data.no_coverage.days, 'days').format('LL')}`
                        setDay1(data.no_coverage.days);
                        setFieldValue("estimatedDeliveryTime",a)
                    }
                })
              })
              .catch((e) => console.log(e));
          }
        }
    
        if (values.customerPostalCode !== "") {
          setPostalCode()
        }
    
      }, [values.customerPostalCode]);
      useEffect( () => {
        if(Object.keys(errors).length > 0) {
            setIsValidF2(false);
        } else{
           handleSubmit();
        }
      },[errors,values])
      useEffect( () => {
        setShowDiv(true)
      },[])

      function handleBlurField(e:any){
        setFieldValue(e.target.name,e.target.value);
        let data :any  = {
            name: formData.step1.nameCustomer,
            surnames: formData.step1.surnamesCustomer,
            email: formData.step1.emailCustomer,
            phone: formData.step1.phoneCustomer,
            mark_phone: formData.step1.customerOptionPhone,
            model_phone: formData.step1.customerModelPhone,
            is_foreign:'',
            imei:''
        }
        const valsCreate = async () => { 
            const d = apiClient.createPreCustomer(data).then((data) => {
                if(data.success) {
                    localStorage.setItem("preCustomer",data.data);
                }
            })
        }
        const valsUpdate = async (newVal: any) => { 
            data.id = newVal;
            data.street = formData.step2.customerStreet
            data.no_interior = formData.step2.customerNoInterior
            data.no_exterior = formData.step2.customerNoExterior
            data.suburb = formData.step2.customerSuburb
            data.postal_code = formData.step2.customerPostalCode
            data.delegation = formData.step2.customerDelegation
            data.state = formData.step2.customerState
            data.country = formData.step2.customerCountry
            data.num_sims = valuesIndex.customerNumChips
            data.plan_name = valuesIndex.customerPlan
            data.amount_order = valuesIndex.amountTotal
            const d = apiClient.updateData(data).then((data) => {
                
            })
        }
        if(localStorage.getItem("preCustomer")){
            let a = localStorage.getItem('preCustomer') || '';
            const id: any = JSON.parse(a);
            if(id){
                valsUpdate(id);
            } else {
                valsCreate();
            }
        } else {
            valsCreate();
        }        
    }
    return (
        <>
        <Box sx={{ width: '100%' }} ref={ref1}>
                    <Slide direction="left"  in={showDiv} container={containerRef.current} timeout={1000}>
                     <form id="div-address" onSubmit={handleSubmit}>
                                <div className="mb-2 col-md-12">
                                    <Chip label="Dirección de envío" color="secondary" variant="outlined" sx={{fontSize:20, color:'white'}}/>
                                    <Divider
                                    sx={{ bgcolor: (theme) => theme.palette.secondary.light }}
                                    style={{
                                        height: 2,
                                        margin: 5,
                                        width:'100%'
                                    }}
                                    />
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-md-6 ">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Calle <span className="requiredLabel">*</span></label>
                                        <input 
                                            type="text"
                                            id="customerStreet" 
                                            name="customerStreet" 
                                            onChange={handleChange} 
                                            onBlur={handleBlurField} 
                                            value={values.customerStreet}  
                                            className={
                                                errors.customerStreet ? " form-control is-invalid" : "form-control"
                                                } 
                                        />
                                        {errors.customerStreet && touched.customerStreet &&(
                                                                                <div className="invalid-feedback">
                                                                                El campo Calle es obligatorio.
                                                                                </div>
                                                                            )} 
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Número Exterior <span className="requiredLabel">*</span> </label>
                                        <input 
                                            type="text"  
                                            id="customerNoExterior" 
                                            name="customerNoExterior" 
                                            onChange={handleChange} 
                                            onBlur={handleBlurField} 
                                            value={values.customerNoExterior}  
                                            className={
                                                errors.customerNoExterior ? " form-control is-invalid" : "form-control"
                                                } 
                                        />
                                        {errors.customerNoExterior && touched.customerNoExterior &&(
                                                                                <div className="invalid-feedback">
                                                                                El campo Número exterior es obligatorio.
                                                                                </div>
                                                                            )} 
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-md-6 ">
                                         <label htmlFor="exampleFormControlInput1" className="form-label">Número Interior </label>{/*<span className="requiredLabel">*</span></label> */}
                                        <input 
                                            type="text" 
                                            id="customerNoInterior" 
                                            name="customerNoInterior" 
                                            onChange={handleChange} 
                                            onBlur={handleBlurField} 
                                            value={values.customerNoInterior}  
                                            className={
                                                errors.customerNoInterior ? " form-control is-invalid" : "form-control"
                                                } 
                                        />
                                        {errors.customerNoInterior && touched.customerNoInterior &&(
                                                                                <div className="invalid-feedback">
                                                                                El campo Número interior es obligatorio.
                                                                                </div>
                                                                            )} 
                                    </div>
                                    
                                    <div className="col-md-6 ">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Código Postal <span className="requiredLabel">*</span></label>
                                        <input 
                                            type="text"  
                                            id="customerPostalCode" 
                                            name="customerPostalCode"  
                                            onChange={handleChange} 
                                            onBlur={handleBlurField} 
                                            value={values.customerPostalCode}  
                                            className={
                                                errors.customerPostalCode ? " form-control is-invalid" : "form-control"
                                                } 
                                        />
                                        {errors.customerPostalCode && touched.customerPostalCode &&(
                                                                                <div className="invalid-feedback">
                                                                                El campo Código Postal interior es obligatorio.
                                                                                </div>
                                                                            )} 
                                    </div>

                                </div>
                                <div className="mb-3 row">
                                <div className="col-md-6">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Colonia <span className="requiredLabel">*</span> </label>
                                        <select 
                                            id="customerSuburb" 
                                            name="customerSuburb" 
                                            onChange={handleChange} 
                                            onBlur={handleBlurField} 
                                            value={values.customerSuburb}  
                                            className={
                                                errors.customerSuburb ? " form-control is-invalid" : "form-control"
                                                } 
                                        >
                                        <option selected>Selecciona una colonia</option>
                                            {colonias.map((data: any,index) => (
                                                <option key={index} value={data?.colonia}>
                                                {data?.colonia}
                                            </option>
                                            ))
                                            }
                                        </select>
                                        {errors.customerSuburb && touched.customerSuburb &&(
                                                                                <div className="invalid-feedback">
                                                                                El campo Colonia interior es obligatorio.
                                                                                </div>
                                                                            )} 
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Municipio / Alcaldía <span className="requiredLabel">*</span> </label>
                                        <input 
                                            type="text"  
                                            id="customerDelegation" 
                                            name="customerDelegation"  
                                            onChange={handleChange} 
                                            onBlur={handleBlurField} 
                                            value={values.customerDelegation}  
                                            disabled={true}
                                            className={
                                                errors.customerDelegation ? " form-control is-invalid disabled-control" : "form-control disabled-control"
                                                } 
                                        />
                                        {errors.customerDelegation && touched.customerDelegation &&(
                                                                                <div className="invalid-feedback">
                                                                                El campo Municipio interior es obligatorio.
                                                                                </div>
                                                                            )} 
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-md-6 ">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Estado <span className="requiredLabel">*</span></label>
                                        <input 
                                            type="text" 
                                            id="customerState" 
                                            name="customerState"   
                                            onChange={handleChange} 
                                            onBlur={handleBlurField} 
                                            value={values.customerState}  
                                            disabled={true}
                                            className={
                                                errors.customerState ? " form-control is-invalid disabled-control"  : "form-control disabled-control"
                                                } 
                                        />
                                        {errors.customerState && touched.customerState &&(
                                                                                <div className="invalid-feedback">
                                                                                El campo Estado es obligatorio.
                                                                                </div>
                                                                            )} 
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">País <span className="requiredLabel">*</span> </label>
                                        <input 
                                            type="text" 
                                            id="customerCountry" 
                                            name="customerCountry"   
                                            onChange={handleChange} 
                                            onBlur={handleBlurField} 
                                            value={values.customerCountry} 
                                            disabled={true} 
                                            className={
                                                errors.customerCountry ? " form-control is-invalid disabled-control" : "form-control disabled-control"
                                                } 
                                        />
                                        {errors.customerCountry && touched.customerCountry &&(
                                                                                <div className="invalid-feedback">
                                                                                El campo País es obligatorio.
                                                                                </div>
                                                                            )} 
                                    </div>
                                </div> 
                        </form>
                    </Slide>           

        </Box>
        </>
  )
}
export default AddressCustomer;
