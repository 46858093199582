import { BrowserRouter, Routes, Route } from "react-router-dom";

import { lazy, Suspense } from "react";
import {Plan} from "./pages/plan"; 
import {Test} from "./pages/plan/test"; 

const Welcome = lazy(() => import("./pages/Welcome"));
const Success = lazy(() => import("./pages/Success"));
const SuccessSim = lazy(() => import("./pages/SuccessSim"));
//const Plan = lazy(() => import("./pages/plan"));

const ROUTES = [
  {
    path: "/",
    element: (
      <Suspense>
        <Welcome />
      </Suspense>
    ),
  },
  {
    path: "/webpayplus/redirect",
    element: (
      <Suspense>
        <Success />
      </Suspense>
    ),
  },
  {
    path: "/webpayplus/redirect-sim/:nbResponse/:importe/:referencia/:nbMoneda/:fecha",
    element: (
      <Suspense>
        <SuccessSim />
      </Suspense>
    ),
  },
  {
    path: "/plan/:id",
    element: (
        <Plan />
    ),
  },
  {
    path: "/contrata/:id",
    element: (
        <Suspense>
        <Test />
      </Suspense>
    ),
  },
];

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        {ROUTES.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}
