import Logo from "../../assets/images/dire-logo-loop.gif";
import BlueBerry from "../../assets/images/materialized-blueberry.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTiktok,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";

import "./style.scss";

function Footer() {
  return (
    <div className="footer">
      <div className="logo">
        <img src={Logo} />
      </div>
      <div className="links">
        <a href="https://diremovil.com/">HOME</a>
        <a href="https://diremovil.com/somos.html">SOMOS</a>
        <a href="https://diremovil.com/planes.html">PLANES</a>
        <a href="https://diremovil.com/terminos.html">TÉRMINOS</a>
        <a href="https://diremovil.com/consideraciones.html">CONSIDERACIONES</a>
        <a href="https://diremovil.com/aviso.html">AVISO DE PRIVACIDAD</a>
        <a href="https://diremovil.com/faqs.html">FAQ'S</a>
      </div>
      <div className="social-media-icons">
        <a
          href="https://www.instagram.com/diremovil/?igshid=YmMyMTA2M2Y="
          target="_blank"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="#" target="_blank">
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a href="https://www.tiktok.com/@diremovil" target="_blank">
          <FontAwesomeIcon icon={faTiktok} />
        </a>
        <a href="#" target="_blank">
          <h4>@diremovil</h4>
        </a>
      </div>
      <div className="logo">
        <img src={BlueBerry} />
      </div>
    </div>
  );
}

export default Footer;
