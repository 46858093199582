
import axios from 'axios';
import {API_URL,API_URL_ECOBRODIRE,API_URL_PERCAPITA, TOKEN_COPOMEX, URL_99_MINUTES, CLIENT_SECRET_99_MINUTES, CLIENT_ID_99_MINUTE, URL_DIRE_PAY} from '../utils/config'

export interface IAddress {
  estado: string;
  municipio: string;
  ciudad: string;
  colonias: IColonia[];
}
export interface IColonia {
  colonia: string;
  tipo_asentamiento: string;
}
export interface IAuth {
  access_token: string;
}

export interface Transaction {
  plan_id: number;
  plan_name: string;
  reference: string;
  phone_number: string;
  status: string;
  datetime: Date;
}

export interface ICustomer {
    id: number;
    name: string;
    surnames: string;
    email: string;
    phone: string;
    phone_mark_id: string;
    model_phone_mark: string;
    is_foreign: boolean;
    imei: string;
    addressId: string;
    created_at: string;
    status_pay: string;
    reference: string;
    quantity_sim: string;
    amount_order: string;
    is_portability: string;
    phone_portability: string;
    CURP_portability: string;
    NIP_portability: string;
    street: string;
    noExterior: string;
    noInterior: string;
    suburb: string;
    postalCode: string;
    delegation: string;
    state: string;
    country: string;
    estimated_delivery_time: string;
    nombre: string;
    amount_chip: string;
    amount_plan: string;
    amount_envio: string;
    location_id: string;
}

export const plans: { [key: string]: any } = {
  "DIRE-ILIMITADO": { name: "Dire 20", id: 1 },
  "DIRE-8": { name: "Dire 8", id: 4 },
  "DIRE-5": { name: "Dire 5", id: 5 },
  "DIRE-ILIMITADO-3": { name: "Dire Ilimitado Trimestral", id: 9 },
  "DIRE-ILIMITADO-6": { name: "Dire Ilimitado Semestral", id: 10 },
  "DIRE-ILIMITADO-12": { name: "Dire Ilimitado Anual", id: 12 },
  "DIRE-100":{ name: "Dire 100", id: 13},
  "DIRE-ILIMITADO-PLUS":{ name: "Dire ilimitado plus", id:14},
  "DIRE-TEST": { name: "Dire test", id: 1000 },
};

export default class ApiClient {
  private URL = API_URL;
  private URLECOBRODIRE = API_URL_ECOBRODIRE;
  private URLPERCAPITA = API_URL_PERCAPITA;
  private TOKEN_COPOMEX_1 = TOKEN_COPOMEX;
  private TOKEN_99_MINUTES: string = "";
  private URL_99_MINUTES = URL_99_MINUTES;
  private CLIENT_SECRET_99_MINUTES = CLIENT_SECRET_99_MINUTES;
  private CLIENT_ID_99_MINUTE = CLIENT_ID_99_MINUTE;
  private URL_DIRE_PAY = URL_DIRE_PAY;
  private static instance: ApiClient;

  private constructor() {
    const token = localStorage.getItem("AUTH_TOKEN_SESSION_99_MINUTES");

    if (token) {
      this.TOKEN_99_MINUTES = token;
    }
  }

  public set token_99_minutes(token: string) {
    this.TOKEN_99_MINUTES = token;
  }
  public getToken99Minutes() {
    return this.TOKEN_99_MINUTES;
  }
  /**
   * Get an instance of the class.
   */
  public static getInstance(): ApiClient {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient();
    }

    return ApiClient.instance;
  }

  /**
   * Endpoint for logging into an account.
   */
  public async getCatalogues() {
    const response = await  fetch(`${URL_DIRE_PAY}/controlventas/getCatalogues`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: 'false',
    });
    if (response.status !== 200) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.error.message);
      }
    
      const data = await response.json();
      return data;
  };

  public async updateData(dataJson: any){
    const response = await  fetch(`${URL_DIRE_PAY}/controlventas/actualiza`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataJson),
    });
    if (response.status !== 200) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.error.message);
      }
    
      const data = await response.json();
      return data;
  }

  public async createPreCustomer(dataJson: any){
    const response = await  fetch(`${URL_DIRE_PAY}/controlventas/createPreCustomer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataJson),
    });
    if (response.status !== 200) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.error.message);
      }
    
      const data = await response.json();
      return data;
  }

  public async getAdrressFromZipCode(zip: any): Promise<IAddress> {
      const response = await fetch(`${this.URLPERCAPITA}/loan/postal-code-dire/${zip}`, {
      method: "GET",
      headers: {
      },
    });

    if (response.status !== 200) {
      console.log(await response.json());
      throw new Error("Error inesperado");
    }


    const data = await response.json();
    return data;
  }

  public async getPostalCode(postalCode: any){
    let a = "f606b1b7-2f50-4359-bbc9-8d0dfb0c2b0d";
    const resp = await axios.get(`https://api.copomex.com/query/info_cp/${postalCode}?type=simplified&token=${a}`);
    const {estado, ciudad, municipio, asentamiento} = resp.data.response;

    let colonias = asentamiento.map((value: any) => {return {colonia: value}})

    const response = {
        estado,
        ciudad,
        municipio,
        colonias
    }
    return response;
  }

  public async createCustomer(dataJson: any){
    const response =  await fetch(`${URL_DIRE_PAY}/controlventas/createCustomer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataJson),
    });
    if (response.status !== 200) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.error.message);
      }
    
      const data = await response.json();
      return data;
  }

  public async validateStock(dataJson: any){
    const response = await  fetch(`${URL_DIRE_PAY}/controlventas/validateStock`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataJson),
    });
    if (response.status !== 200) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.error.message);
      }
    
      const data = await response.json();
      return data;
  }



  public async  getURLPayment  (
    reference: string,
    amount: number,
    phone_number: string,
    plan_name: string,
    no_chips: number
  )  {
    const data = {
      version: "IntegraWPP",
      url: {
        reference: reference,
        amount: amount,
        moneda: "MXN",
        canal: "W",
        datos_adicionales: {
          data: [
            {
              attr_id: 1,
              attr_display: "true",
              label: "PLAN DIRE",
              value: plans[plan_name].name,
            },
            {
              attr_id: 2,
              attr_display: "false",
              label: "plan_id",
              value: plans[plan_name].id,
            },
            {
              attr_id: 3,
              attr_display: "true",
              label: "Cantidad SIM",
              value: no_chips,
            },
          ],
        },
      },
    };
  
    const response = await fetch(`${API_URL}/diremovil/create-payment-link`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }
  
    return await response.json();
  };

  public async getAleatorio(): Promise<String>{
    const response = await fetch('https://www.random.org/strings/?num=1&len=13&digits=on&upperalpha=on&loweralpha=on&unique=on&format=plain&rnd=new');
    const data = await response.text();
    return data;
  }

  public async updateDataCustomer(dataJson: any){
    const response = await  fetch(`${URL_DIRE_PAY}/controlventas/updateCustomer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataJson),
    });
    if (response.status !== 200) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.error.message);
      }
    
      const data = await response.json();
      return data;
  }

  public async validateImei(params:any){

    const response = await  fetch(`${URL_DIRE_PAY}/controlsolicitudes/checkIMEI?imei=`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
    if (response.status !== 200) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.error.message);
      }
    
      const data = await response.json();
      return data;
  }

  public async loginInto99Minutes():Promise<IAuth>{
    const response = await fetch(`${URL_DIRE_PAY}/controlventas/auth99Minutes`,{
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    } );
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }
  
    const data = await response.json();
    return data;
  }

  public async registerLocation(dataJson: any) {
    console.log(this.TOKEN_99_MINUTES)
    const response  = await fetch(`${URL_DIRE_PAY}/controlventas/registerLocation`,{
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.TOKEN_99_MINUTES}`
      },
      body: JSON.stringify(dataJson)
    } );
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }
  
    const data = await response.json();
    return data;
  }

  public async validateDeliveryTime(dataJ:any){
    const response = await fetch(`${URL_DIRE_PAY}/controlventas/validateDeliveryTime`,{
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataJ)
    } );
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }
  
    const data = await response.json();
    return data;
  }
  public async senEmail(dataJson: any) {
    const response = await fetch(`${URL_DIRE_PAY}/controlventas/sendEmail`,{
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataJson)
    } );
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }
  
    const data = await response.json();
    return data;
  }

  public async getDataCustomer(dataJson: any):Promise<ICustomer>{
    const response = await  fetch(`${URL_DIRE_PAY}/controlventas/getCustomerByReference`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataJson),
    });
    if (response.status !== 200) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.error.message);
      }
    
      const data = await response.json();
      return data.success;
  }

  public async createCustomerOrder(dataJson: any){
    const response = await  fetch(`${URL_DIRE_PAY}/controlventas/createOrder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "authorization": `Bearer ${this.TOKEN_99_MINUTES}`
      },
      body: JSON.stringify(dataJson),
    });
    if (response.status !== 200) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.error.message);
      }
    
      const data = await response.json();
      return data.success;
  }

  public async validateCustomerOrder(dataJson: any){
    const response = await  fetch(`${URL_DIRE_PAY}/controlventas/validateCustomerOrder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataJson),
    });
    if (response.status !== 200) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.error.message);
      }
    
      const data = await response.json();
      return data;
  }

  public async saveTransaction  (transaction: Transaction)  {
    const response = await fetch(`${API_URL}/diremovil/save-transaction`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(transaction),
    });
  
    if (response.status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error.message);
    }
  };
}
