import React,{useEffect, useState} from 'react';
import "./style.scss";
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {  useFormik } from "formik";
import ApiClient from "../../services/apiClient";
import * as Yup from "yup";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
function DataCustomer({nextStep, setFormData, formData, setIsValidF1,valuesIndex }:{nextStep:any,setFormData:any, formData:any, setIsValidF1:any, valuesIndex: any})  {
    const apiClient = ApiClient.getInstance();
    const [marks,setMarks] = useState([]);
    const [models,setModels] = useState([]);
    const [modelsFiltered, setModelsFiltered] = useState([]);
    const containerRef = React.useRef(null);
    const [showDiv,setShowDiv] = useState(false);
    const [isInvalidImei, setIsInvalidImei] = useState(true);
    const [isLoadingImei, setIsLoadingImei] = useState(false);
    const ref2 = React.useRef<null | HTMLButtonElement>(null);
    const [notValidatedImei, setNotValidatedImei] = useState(true);
    useEffect(() => {
        nextStep();
        const catalogues = async () => { 
            const data = apiClient.getCatalogues().then((data:any) => {
                setMarks(data.marks);
                setModels(data.models);
                setShowDiv(true);
            })
        }
        catalogues();
    },[]);
    function handleChangeOption(e:any){
        setFieldValue("customerModelPhone", "");
        setFieldValue("customerOptionPhone", e.target.value);
        const data = models.filter((model: any) => model.phone_mark_id === e.target.value); 
        console.log(data)
        setModelsFiltered(data);
    }

    function handleBlurField(e:any){
        setFieldValue(e.target.name,e.target.value);
        let data :any  = {
            name: values.nameCustomer,
            surnames: values.surnamesCustomer,
            email: values.emailCustomer,
            phone: values.phoneCustomer,
            mark_phone: values.customerOptionPhone,
            model_phone: values.customerModelPhone,
            is_foreign:false,
            imei:''
        }
        const valsCreate = async () => { 
            const d = apiClient.createPreCustomer(data).then((data) => {
                if(data.success) {
                    localStorage.setItem("preCustomer",data.data);
                }
            })
        }
        const valsUpdate = async (newVal: any) => { 
            data.id = newVal;
            data.street = formData.step2.customerStreet
            data.no_interior = formData.step2.customerNoInterior
            data.no_exterior = formData.step2.customerNoExterior
            data.suburb = formData.step2.customerSuburb
            data.postal_code = formData.step2.customerPostalCode
            data.delegation = formData.step2.customerDelegation
            data.state = formData.step2.customerState
            data.country = formData.step2.customerCountry
            data.num_sims = valuesIndex.customerNumChips
            data.plan_name = valuesIndex.customerPlan
            data.amount_order = valuesIndex.amountTotal
            const d = apiClient.updateData(data).then((data) => {
                
            })
        }
        if(localStorage.getItem("preCustomer")){
            let a = localStorage.getItem('preCustomer') || '';
            const id: any = JSON.parse(a);
            if(id){
                valsUpdate(id);
            } else {
                valsCreate();
            }
        } else {
            valsCreate();
        }        
    }

    const handleClickImei = async() => {
        setIsLoadingImei(true);
        let data: any = {
            imei: values.customerImei
        }
        await apiClient.validateImei(data).then((res) => {
            console.log(res);
            setIsLoadingImei(false);
            if(res.result){
                const data = res.result;
            if(data.homologated == 'HOMOLOGADO'){
                setNotValidatedImei(false)
                var message = `¡Felicidades! tu dispositivo ${data.brand} ${data.model} es compatible, ahora puedes continuar con el proceso de compra`;
                Swal.fire(
                    '¡Felicidades!',
                    `Tu dispositivo ${data.brand} ${data.model} es compatible, ahora puedes continuar con el proceso de compra.`,
                    'success'
                )
                // document.getElementById("result").classList.add('animate__animated','animate__fadeInUpBig');
                // document.getElementById("message_asd").innerHTML = message;
            } else {
                Swal.fire(
                    'Error',
                    `Lo sentimos, tu dispositivo no es compatible.`,
                    'error'
                )
            }
            } else if(res.error){
                Swal.fire(
                    'Error',
                    `Lo sentimos, tu dispositivo no es compatible.`,
                    'error'
                )
            }
        })
    }

        const handleChangeImei = (e: any) => {
            const re = /^[0-9\b]+$/; //rules
            if (e.target.value === "" || re.test(e.target.value)) {
              setFieldValue(e.target.name,e.target.value);
              if(e.target.value.length >=14){
                return setIsInvalidImei(false)
              } else {
                return  setIsInvalidImei(true)
              }
            }
            return setIsInvalidImei(true)
        }

    function handleChangePhoneModel(e:any){
        setFieldValue("customerModelPhone",e.target.value);
        let data :any  = {
            mark_phone: values.customerOptionPhone,
            surnames: values.customerModelPhone,
        }
        const valsUpdate = async (newVal: any) => { 
            data.id = newVal;
            const d = apiClient.updateData(data).then((data) => {
                if(data.success) {
                    localStorage.setItem("preCustomer",data.data);
                }
            })
        }
        if(localStorage.getItem("preCustomer")){
            let a = localStorage.getItem('preCustomer') || '';
            const id: any = JSON.parse(a);
            if(id){
                valsUpdate(id);
            }
        }        
    }

      let validation = Yup.object().shape({
        nameCustomer: Yup.string().min(4).required('Requerido'),
        surnamesCustomer: Yup.string().min(4).required('Requerido'),
        emailCustomer: Yup.string().min(6).required('Requerido'),
        phoneCustomer: Yup.string().min(10).required('Requerido'), 
        isPortability: Yup.boolean(),
        isForeign: Yup.boolean(),
        portabilityPhone: Yup.string()
            .when('isPortability', {
            is: true, // alternatively: (val) => val == true
            then: (schema) => schema.required('requerido'),
            otherwise: (schema) => schema.notRequired(),
            }),
        portabilityNIP: Yup.string()
        .when('isPortability', {
        is: true, // alternatively: (val) => val == true
        then: (schema) => schema.required('requerido'),
        otherwise: (schema) => schema.notRequired(),
        }),
        portabilityCURP: Yup.string().notRequired(),
        customerOptionPhone: Yup.string()
        .when('isForeign', {
        is: false, // alternatively: (val) => val == true
        then: (schema) => schema.required('requerido'),
        otherwise: (schema) => schema.notRequired(),
        }),
        customerModelPhone: Yup.string()
        .when('isForeign', {
        is: false, // alternatively: (val) => val == true
        then: (schema) => schema.required('requerido'),
        otherwise: (schema) => schema.notRequired(),
        }), 
    });
    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isValid, validateField,
        dirty
      } = useFormik({
        enableReinitialize: true,
        isInitialValid:false,
        validateOnMount:true,
        initialValues: {
          nameCustomer: "",
          surnamesCustomer: "",
          emailCustomer: "",
          phoneCustomer: "",
          customerOptionPhone:"",
          customerModelPhone:"",
          isForeign:false,
          customerImei:'',
          isPortability: false,
          portabilityPhone: "",
          portabilityCURP: "",
          portabilityNIP: "",
        },
        validationSchema: validation,
        onSubmit: (values) => {
            setIsValidF1(true);
            let step1 = {
                nameCustomer: values.nameCustomer,
                surnamesCustomer: values.surnamesCustomer,
                emailCustomer: values.emailCustomer,
                phoneCustomer: values.phoneCustomer,
                customerOptionPhone:values.customerOptionPhone,
                customerModelPhone:values.customerModelPhone,
                isForeign:values.isForeign,
                IMEI: values.customerImei,
                isPortability: values.isPortability,
                phonePortability: values.portabilityPhone,
                CURPPortability: values.portabilityCURP,
                NIPPortability: values.portabilityNIP,
            }
            formData.step1 = step1;
            setFormData(formData);
        },
      });



      const  handleChangeField = async(e:any) => {
        if(e.target.value === ''){
            setIsValidF1(false);
        }
        if( values.nameCustomer !==  '' &&
        values.surnamesCustomer !==  '' &&
        values.emailCustomer !==  '' &&
        values.phoneCustomer !==  '' &&
        values.customerOptionPhone !==  '' &&
        values.customerModelPhone !==  '' ) {
           // alert('handle on blur')
        }
        setFieldValue(e.target.name, e.target.value);
        if(Object.keys(errors).length > 0 ) {
            setIsValidF1(false);
        } else {
            handleSubmit();
        }

        // if( values.nameCustomer === '' ||
        //     values.surnamesCustomer === '' ||
        //     values.emailCustomer === '' ||
        //     values.phoneCustomer === '' ||
        //     values.customerOptionPhone === '' ||
        //     values.customerModelPhone === '' ) {
        //         console.log('valid false')
        //         setIsValidF1(false);
        //     }
           
      }
    //   const  handleBlurField = async(e:any) => {
    //     //console.log('162 info-personal', isValid)
    //     if(e.target.value === ''){
    //         setIsValidF1(false);
    //     }
    //     if( values.nameCustomer !==  '' &&
    //     values.surnamesCustomer !==  '' &&
    //     values.emailCustomer !==  '' &&
    //     values.phoneCustomer !==  '' &&
    //     values.customerOptionPhone !==  '' &&
    //     values.customerModelPhone !==  '' ) {
    //     }
    //     setFieldValue(e.target.name, e.target.value);
    //     if(Object.keys(errors).length > 0 ) {
    //         //console.log('Object.keys(errors).length > 0 ')
    //         setIsValidF1(false);
    //     } else {
    //         handleSubmit();
    //     }

    //     // if( values.nameCustomer === '' ||
    //     //     values.surnamesCustomer === '' ||
    //     //     values.emailCustomer === '' ||
    //     //     values.phoneCustomer === '' ||
    //     //     values.customerOptionPhone === '' ||
    //     //     values.customerModelPhone === '' ) {
    //     //         console.log('valid false')
    //     //         setIsValidF1(false);
    //     //     }
           
    //   }
    //   useEffect(() => {
    //     // const a = async() =>{
    //     //     validateField("nameCustomer").then((d) => {console.log(d)})
    //     //     console.log( validateField("nameCustomer"),
    //     // validateField("surnamesCustomer"),
    //     // validateField("emailCustomer"),
    //     // validateField("phoneCustomer"),
    //     // validateField("customerOptionPhone"),
    //     // validateField("customerModelPhone"));
    //     // }
    //     //console.log('use effect values')
    //     if( values.nameCustomer !==  '' &&
    //     values.surnamesCustomer !==  '' &&
    //     values.emailCustomer !==  '' &&
    //     values.phoneCustomer !==  '' &&
    //     values.customerOptionPhone !==  '' &&
    //     values.customerModelPhone !==  '' ) {
    //     //     if(Object.keys(errors).length > 0 ) {
    //     //     console.log('Object.keys(errors).length > 0 ')
    //     //     setIsValidF1(false);
    //     // } else {
    //     //     handleSubmit();
    //     // }
    //     ref2.current?.click();
    //     }
    //     // setIsValidF1(false);
    //     // if( values.nameCustomer === '' ||
    //     //     values.surnamesCustomer === '' ||
    //     //     values.emailCustomer === '' ||
    //     //     values.phoneCustomer === '' ||
    //     //     values.customerOptionPhone === '' ||
    //     //     values.customerModelPhone === '' ) {
    //     //         console.log('valid false')
    //     //         setIsValidF1(false);
    //     //     }
       
    //   },[values])
      
      useEffect( () => {
        console.log('changed',Object.keys(errors).length)
        if(Object.keys(errors).length > 0){
            setIsValidF1(false);
        } else {
            handleSubmit();
           //setIsValidF1(true);
        }
      },[errors,values])


    return (
        <>
        <Box sx={{ width: '100%' }}>            
            <Slide direction="right"  in={showDiv} container={containerRef.current} timeout={1000}>
                        <form id="div-user" onSubmit={handleSubmit}>
                            <Grid >
                                        <div className="mb-2 col-md-12">
                                            <Chip label="Información de contacto" color="secondary" variant="outlined" sx={{fontSize:20, color:'white'}}/>
                                            <Divider
                                            sx={{ bgcolor: (theme) => theme.palette.secondary.light }}
                                            style={{
                                                height: 2,
                                                margin: 5,
                                                width:'100%'
                                            }}
                                            />
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-md-6 ">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Nombre(s) <span className="requiredLabel">*</span></label>
                                                <input 
                                                    type="text"  
                                                    id="nameCustomer" 
                                                    name="nameCustomer" 
                                                    onChange={handleChange} 
                                                    onBlur={handleBlurField} 
                                                    value={values.nameCustomer}  
                                                    className={
                                                        errors.nameCustomer ? " form-control is-invalid" : "form-control"
                                                        }
                                                />
                                                {errors.nameCustomer && touched.nameCustomer &&(
                                                                                        <div className="invalid-feedback">
                                                                                        El campo Nombre es obligatorio.
                                                                                        </div>
                                                                                    )}
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Apellidos <span className="requiredLabel">*</span> </label>
                                                <input 
                                                    type="text" 
                                                    id="surnamesCustomer" 
                                                    name="surnamesCustomer" 
                                                    onChange={handleChange} 
                                                    onBlur={handleBlurField} 
                                                    value={values.surnamesCustomer}  
                                                    className={
                                                        errors.surnamesCustomer ? " form-control is-invalid" : "form-control"
                                                        }
                                                />
                                                {errors.surnamesCustomer && touched.surnamesCustomer &&(
                                                                                        <div className="invalid-feedback">
                                                                                        El campo Apellido es obligatorio.
                                                                                        </div>
                                                                                    )}
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-md-6 mt-2">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Correo electrónico <span className="requiredLabel">*</span></label>
                                                <input 
                                                    type="email" 
                                                    id="emailCustomer" 
                                                    name="emailCustomer"
                                                    onChange={handleChange} 
                                                    onBlur={handleBlurField} 
                                                    value={values.emailCustomer}  
                                                    className={
                                                        errors.emailCustomer ? " form-control is-invalid" : "form-control"
                                                        } 
                                                />
                                                {errors.emailCustomer && touched.emailCustomer &&(
                                                                                        <div className="invalid-feedback">
                                                                                        El campo Correo electrónico es obligatorio.
                                                                                        </div>
                                                                                    )}                        
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Teléfono (10 dígitos) <span className="requiredLabel">*</span> <><Tooltip  placement="right-end" title="Lo usamos solo como medio de contacto para la paquetería en caso de ser necesario." sx={{width:'5',height:'5', color:'orange'}}><IconButton size='small'><InfoOutlinedIcon  /></IconButton></Tooltip></></label>
                                                <input 
                                                    type="number" 
                                                    id="phoneCustomer"
                                                    name="phoneCustomer"
                                                    onChange={handleChange} 
                                                    onBlur={handleBlurField} 
                                                    value={values.phoneCustomer}  
                                                    maxLength={10}
                                                    className={
                                                        errors.phoneCustomer ? " form-control is-invalid" : "form-control"
                                                        } 
                                                />
                                                {errors.phoneCustomer && touched.phoneCustomer &&(
                                                                                        <div className="invalid-feedback">
                                                                                        El campo Teléfono es obligatorio.
                                                                                        </div>
                                                                                    )}                        
                                            </div>
                                        </div>
                                        <div className="mb-5 row">
                                            <div className="form-check m-2">
                                                <div className="col-md-8 ">
                                                    <input 
                                                        className="form-check-input" 
                                                        type="checkbox" 
                                                        checked={values.isPortability}
                                                        id="isPortability"
                                                        name="isPortability"
                                                        onChange={() => {
                                                            setFieldValue("customerImei","");
                                                            setIsInvalidImei(true);
                                                            setIsLoadingImei(false);
                                                            setFieldValue("isPortability", !values.isPortability);
                                                        }}
                                                        />
                                                    <label className="form-check-label " htmlFor="flexCheckIndeterminate" style={{color: "white"}}>
                                                        ¿Deseas realizar la portabilidad con nosotros?
                                                    </label>
                                                </div>
                                            </div>   
                                            {values.isPortability ?
                                            <Slide direction="up"  in={values.isPortability}  timeout={500}>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="formIMEI">
                                                        
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} md={6} lg={6} xl={6}>
                                                                <div className="col">
                                                                        <label htmlFor="exampleFormControlInput1" className="form-label">Teléfono (10 dígitos) <span className="requiredLabel">*</span> <><Tooltip  placement="right-end" title="Es el número al cual deseas hacer la portabilidad" sx={{width:'5',height:'5', color:'green'}}><IconButton size='small'><InfoOutlinedIcon  /></IconButton></Tooltip></></label>
                                                                        <input 
                                                                            type="number" 
                                                                            id="portabilityPhone"
                                                                            name="portabilityPhone"
                                                                            onChange={handleChange} 
                                                                            onBlur={handleBlurField} 
                                                                            value={values.portabilityPhone}  
                                                                            maxLength={10}
                                                                            className={
                                                                                errors.portabilityPhone ? " form-control is-invalid" : "form-control"
                                                                                } 
                                                                        />
                                                                        {errors.portabilityPhone && touched.portabilityPhone &&(
                                                                                                                <div className="invalid-feedback">
                                                                                                                El campo Teléfono es obligatorio.
                                                                                                                </div>
                                                                                                            )}                        
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} md={6} lg={6} xl={6}>
                                                            <div className="col">
                                                                    <label htmlFor="exampleFormControlInput1" className="form-label">NIP <span className="requiredLabel">*</span> <><Tooltip aria-disabled placement="right-end" title="Código que obtienes enviando un SMS  desde tu teléfono con la palabra NIP al 051" sx={{width:'50',height:'50', color:'orange', fontSize:'20px'}}><IconButton size='small'><InfoOutlinedIcon  /></IconButton></Tooltip></></label>
                                                                    <input 
                                                                        type="number" 
                                                                        id="portabilityNIP"
                                                                        name="portabilityNIP"
                                                                        onChange={handleChange} 
                                                                        onBlur={handleBlurField} 
                                                                        value={values.portabilityNIP}  
                                                                        maxLength={10}
                                                                        className={
                                                                            errors.portabilityNIP ? " form-control is-invalid" : "form-control"
                                                                            } 
                                                                    />
                                                                    {errors.portabilityNIP && touched.portabilityNIP &&(
                                                                                                            <div className="invalid-feedback">
                                                                                                            El campo NIP es obligatorio.
                                                                                                            </div>
                                                                                                        )}                        
                                                            </div>
                                                            </Grid>
                                                            <Grid item xs={12} md={6} lg={6} xl={6}>
                                                                <div className="col">
                                                                        <label htmlFor="exampleFormControlInput1" className="form-label">CURP </label>
                                                                        <input 
                                                                            type="text" 
                                                                            id="portabilityCURP"
                                                                            name="portabilityCURP"
                                                                            onChange={handleChange} 
                                                                            onBlur={handleBlurField} 
                                                                            value={values.portabilityCURP}  
                                                                            maxLength={18}
                                                                            className={
                                                                                errors.portabilityCURP ? " form-control is-invalid" : "form-control"
                                                                                } 
                                                                        />
                                                                        {errors.portabilityCURP && touched.portabilityCURP &&(
                                                                                                                <div className="invalid-feedback">
                                                                                                                El campo Teléfono es obligatorio.
                                                                                                                </div>
                                                                                                            )}                        
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        
                                                    </div>
                                                </div> 
                                        </Slide>
                                         : ''}
                                                                                
                                        </div>
                                        {!values.isForeign ? <div className="mb-3 row">
                                            <h5 style={{color:'white'}}>Dispositivo a usar con tu plan</h5>
                                            <label className="form-label mt-1">Esto para garantizar un buen servicio</label>
                                            <div className="col-md-6 ">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Marca de tu equipo <span className="requiredLabel">*</span></label>
                                                <select 
                                                    id="customerOptionPhone" 
                                                    name="customerOptionPhone"
                                                    onChange={handleChangeOption} 
                                                    onBlur={handleBlurField} 
                                                    value={values.customerOptionPhone}  
                                                    className={
                                                        errors.customerOptionPhone ? " form-control is-invalid" : "form-control"
                                                        } 
                                                >
                                                    <option selected>Selecciona un dispositivo</option>
                                                    {marks.map((mark: any, index) => (
                                                        <option key={mark.id} value={mark.id}>{mark.name}</option>
                                                    ))
                                                    }
                                                </select>
                                                {errors.customerOptionPhone && touched.customerOptionPhone &&(
                                                                                        <div className="invalid-feedback">
                                                                                            Elige la marca de tu equipo
                                                                                        </div>
                                                                                    )}                        
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Modelo de tu equipo <span className="requiredLabel">*</span> </label>
                                                <select 
                                                    id="customerModelPhone"
                                                    name="customerModelPhone"
                                                    onChange={handleChange} 
                                                    onBlur={handleBlurField} 
                                                    value={values.customerModelPhone}  
                                                    className={
                                                        errors.customerModelPhone ? " form-control is-invalid" : "form-control"
                                                        } 
                                                >
                                                    <option selected>Selecciona un dispositivo</option>
                                                    {modelsFiltered.map((model: any, index) => (
                                                        <option key={model.id} value={model.model_name}>{model.model_name}</option>
                                                    ))
                                                    }
                                                </select>
                                                {errors.customerModelPhone && touched.customerModelPhone &&(
                                                                                        <div className="invalid-feedback">
                                                                                            Elige el modelo de tu equipo
                                                                                        </div>
                                                                                    )}                        
                                            </div>
                                        </div> : ''}
                                        
                                        <div className="mb-5 row">
                                            <div className="form-check m-2">
                                                <div className="col-md-8 ">
                                                    <input 
                                                        className="form-check-input" 
                                                        type="checkbox" 
                                                        checked={values.isForeign}
                                                        id="isForeign"
                                                        name="isForeign"
                                                        onChange={() => {
                                                            setFieldValue("customerImei","");
                                                            setIsInvalidImei(true);
                                                            setIsLoadingImei(false);
                                                            setFieldValue("isForeign", !values.isForeign);
                                                        }}
                                                        />
                                                    <label className="form-check-label " htmlFor="flexCheckIndeterminate" style={{color: "white"}}>
                                                        Si tu dispositivo no se encuentra en la lista, marca esta opción.
                                                    </label>
                                                </div>
                                            </div>   
                                            {values.isForeign ?
                                            <Slide direction="up"  in={values.isForeign}  timeout={500}>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="formIMEI">
                                                        {notValidatedImei ?
                                                        <>
                                                        <p className="textFormIMEI">Necesitamos validar la compatibilidad de tu equipo en nuestra Red 4G.<br/>Marca al <strong>*#06#</strong> desde tu celular y por favor introduce el número IMEI aquí.</p>
                                                        <p className="textFormIMEI">Recuerda que el chip está diseñado solo para celulares, no para modem, tableta o cualquier dispositivo que no sea un celular.</p>
                                                        <label className="LabelFormCheckout">IMEI de 14 a 16 Digitos <span className="requiredLabel">*</span></label>
                                                        <Grid container spacing={2}>
                                                        <Grid item xs={12} md={6} lg={6} xl={6}>
                                                        <Paper
                                                            component="form"
                                                            sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 'auto' }}
                                                            >
                                                            <IconButton sx={{ p: '1px' }} aria-label="menu">
                                                                <DeviceUnknownIcon />
                                                            </IconButton>
                                                            <InputBase
                                                                sx={{ ml: 1, flex: 5 , width: '100%'}}
                                                                name='customerImei'
                                                                type='text'
                                                                id="customerImei"
                                                                placeholder="Ej. 354651106866421"
                                                                inputProps={{ 'aria-label': 'search google maps', 'maxlength' : '16' }}
                                                                value={values.customerImei}
                                                                onChange={handleChangeImei}
                                                                onBlur={handleChangeImei}
                                                            />
                                                        </Paper>
                                                        </Grid>
                                                        <Grid item xs={12} md={6} lg={6} xl={6}>
                                                            <Button variant="contained" endIcon={isLoadingImei ? '' : <SearchIcon />} disabled={isInvalidImei} onClick={handleClickImei}>
                                                                {isLoadingImei ? <CircularProgress color="inherit" /> : 'Consultar'}
                                                            </Button>
                                                        </Grid>
                                                        </Grid>
                                                        </>
                                                        : <label style={{color: 'green'}}><strong>Tu dispositivo es compatible, puedes continuar con el pago.</strong></label>
                                                         }
                                                        
                                                    </div>
                                                </div> 
                                        </Slide>
                                         : ''}
                                                                                
                                        </div>
                            </Grid>
                        </form>
            </Slide>         
        </Box>
        </>
  )
}

export default DataCustomer;
