import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";

import "./style.scss";
import { Dispatch } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import logo_blanco from "../../assets/images/dire-logo.png";
interface PaymentIframeProps {
  handleClose: Dispatch<boolean>;
  url: string;
}

function PaymentIframe(props: PaymentIframeProps) {
  const [loading, setIsLoading] = useState(true);
  const handleIfrmeLoad = () => {
    setIsLoading(false);
  };
  return (
    <div className="payment-iframe">
      <div className="background"></div>
      <FontAwesomeIcon
        size="3x"
        color="white"
        icon={faXmark}
        onClick={() => window.location.reload()}
        cursor="pointer"
      />
      <iframe
        src={props.url}
        scrolling="si"
        seamless={true}
        onLoad={handleIfrmeLoad}
      ></iframe>
      <Backdrop
        sx={{ color: "#173A5E", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={loading}
      >
        <CircularProgress color="primary" size={100} />
      </Backdrop>
    </div>
  );
}

export default PaymentIframe;
